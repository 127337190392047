


// .makeStyles-card-185 {
//     margin-bottom: 0px;
// }

div .detailBanner {
    height: 83%;
}

div .detailBudget {
    height: 83%;
}

div .detailHours {
    margin-top: 0px;
}

div .detailCompany {
    height: 93%;
    margin-top: 0px;
}

div .genderCheckBox {
    height: 93%;
    margin-top: 0px;
}

div .detailLocations {
    margin-top: 0px;
    height: 95%;
}

div .campaignStore {
    margin-top: 0px;
}

div .detailInfo {
    height: 91.5%;
}

